exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".JR7ex .Ypdbi{background-color:#c4424f;height:200px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.JR7ex .Ypdbi ._1N9Bj{width:1180px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;margin:0 auto}.JR7ex .Ypdbi ._1N9Bj ._11oKs{color:#fff;-webkit-box-sizing:border-box;box-sizing:border-box;border:solid 1px #eef1f7;opacity:0.2;width:300px;height:106px;padding:14px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:start;justify-content:flex-start;cursor:pointer;border-radius:5px;background:none}.JR7ex .Ypdbi ._1N9Bj ._11oKs:hover{border-style:dashed}.JR7ex .Ypdbi ._1N9Bj ._11oKs>img{margin-right:14px}.JR7ex .Ypdbi ._1N9Bj ._11oKs>div>h3,.JR7ex .Ypdbi ._1N9Bj ._11oKs>div>p{margin:0;padding:0}.JR7ex .Ypdbi ._1N9Bj ._37Q3H{opacity:1;background:rgba(255,255,255,0.1)}.JR7ex .Ypdbi ._1N9Bj ._3B0bi{opacity:0.6}.JR7ex .hcSjh{width:625px;height:33px;margin:0 auto;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;color:#eef1f7;font-weight:400;background:#c4424f;background-size:500px}.JR7ex .hcSjh::before{content:' ';width:0;height:0;border-top:33px solid #c4424f;border-left:45px solid #ececec}.JR7ex .hcSjh::after{content:' ';width:0;height:0;border-top:33px solid #c4424f;border-right:45px solid #ececec}\n", ""]);

// exports
exports.locals = {
	"root": "JR7ex",
	"container": "Ypdbi",
	"content": "_1N9Bj",
	"step": "_11oKs",
	"active": "_37Q3H",
	"completed": "_3B0bi",
	"help": "hcSjh"
};