import React from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import s from './SelectIcons.scss';

const Option = ({ innerRef, innerProps, data }) => (
  <div ref={innerRef} className={s.option} {...innerProps}>
    <img height="20px" src={data.icon} alt={data.label} />
    {data.label}
  </div>
);

const SingleValue = ({ innerRef, innerProps, data }) => (
  <div ref={innerRef} className={s.selected} {...innerProps}>
    <img height="20px" src={data.icon} alt={data.label} />
    {data.label}
  </div>
);

const DropdownIndicator = props => (
  <components.DropdownIndicator
    className={props.selectProps.menuIsOpen && s.dropdownFocus}
    {...props}
  />
);

class SelectIcons extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.any,
        icon: PropTypes.any,
      }),
    ).isRequired,
    className: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    selected: PropTypes.any,
  };

  constructor(props) {
    super(props);
    const { data, selected } = props;
    const state = { selected: null };
    data.forEach(option => {
      if (option && option.value === selected) state.selected = selected;
    });
    this.state = state;
  }

  render() {
    const { placeholder, data, className, handleChange, selected } = this.props;
    return (
      <div className={cx(s.root, className)}>
        <Select
          placeholder={placeholder}
          options={data}
          value={selected}
          components={{ Option, SingleValue, DropdownIndicator }}
          onChange={sd => handleChange(sd)}
        />
      </div>
    );
  }
}

export default withStyles(s)(SelectIcons);
