import React from 'react';
import Home from './Home';
import Layout from '../../components/Layout';
import languages from '../../data/static/country';

const supportedLanguages = languages();

async function action(context) {
  let title = `Frezu up to ${supportedLanguages.length} language translation`;
  const { params } = context;
  if (params.fromLanguage && params.toLanguage) {
    if (params.fromLanguage === params.toLanguage)
      return {
        redirect: '/',
        status: 301,
      };

    const fromLanguage = supportedLanguages
      .filter(l => l.slug === params.fromLanguage)
      .pop();
    const toLanguage = supportedLanguages
      .filter(l => l.slug === params.toLanguage)
      .pop();
    if (!fromLanguage || !toLanguage)
      return {
        redirect: '/',
        status: 301,
      };
    title = `Translate from ${fromLanguage.name} to ${toLanguage.name}`;
  }
  return {
    title,
    chunks: ['home'],
    component: (
      <Layout>
        <Home context={context} />
      </Layout>
    ),
  };
}

export default action;
