import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Dropzone from 'react-dropzone';
import posed from 'react-pose';
import s from './Home.scss';
import Infobar from '../../containers/Infobar/Infobar';
import Select from '../../components/SelectIcons/SelectIcons';
import languages from '../../data/static/country';
import wordCounter from '../../utils/wordCounter';

import {
  updateHelp,
  updateStep,
  updateFromLanguage,
  updateToLanguage,
  updateUserFile,
  updateUserText,
} from '../../actions/userProgress';

const Item = posed.div({
  closed: { height: 0, transition: { duration: 150 } },
  open: { height: 'auto', delay: 300 },
});

const supportedLanguages = languages();
const options = supportedLanguages.map(e => ({
  value: e.name,
  label: e.name,
  icon: e.flag,
}));

const PRICE = 0.04;

class Home extends React.Component {
  static propTypes = {
    userProgress: PropTypes.shape({
      completed: PropTypes.arrayOf(PropTypes.number),
      current: PropTypes.number,
      help: PropTypes.string,
      fromLanguage: PropTypes.object,
      toLanguage: PropTypes.object,
      userFile: PropTypes.any,
      userText: PropTypes.string,
    }).isRequired,
    updateHelp: PropTypes.func.isRequired,
    updateStep: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { params } = props.context;
    if (params.fromLanguage) {
      const fromLanguage = supportedLanguages
        .filter(l => l.slug === params.fromLanguage)
        .pop();
      props.updateFromLanguage(
        options.filter(o => o.value === fromLanguage.name).pop(),
      );
    }
    if (params.toLanguage) {
      const toLanguage = supportedLanguages
        .filter(l => l.slug === params.toLanguage)
        .pop();
      props.updateToLanguage(
        options.filter(o => o.value === toLanguage.name).pop(),
      );
    }

    this.state = {
      fileWords: 0,
    };

    this.handleStep1 = this.handleStep1.bind(this);
  }

  // componentDidUpdate(prevProps) {
  //   if (
  //     prevProps.userProgress.fromLanguage !==
  //       this.props.userProgress.fromLanguage ||
  //     prevProps.userProgress.toLanguage !== this.props.userProgress.toLanguage
  //   ) {
  //     this.getHelp();
  //   }
  // }

  getUploadedFiles() {
    const { userProgress } = this.props;
    if (!userProgress) return '';
    const { userFile } = userProgress;
    if (!userFile || userFile.length <= 0) return '';

    return (
      <aside>
        <h4>Uploaded files:</h4>
        <ol>
          {userFile.map(file => (
            <li key={file.path}>{`${file.path} (${file.size}) bytes`}</li>
          ))}
        </ol>
      </aside>
    );
  }

  handleStep1() {
    const { userProgress } = this.props;
    const { fromLanguage, toLanguage } = userProgress;
    if (fromLanguage && toLanguage && fromLanguage !== toLanguage)
      this.props.updateStep(userProgress.current + 1);
  }

  renderStep() {
    const { current } = this.props.userProgress;
    return (
      <div>
        <Item
          key="1"
          className={s.stepContent}
          pose={current === 1 ? 'open' : 'closed'}
          initialPose="closed"
        >
          {this.renderStep1()}
        </Item>
        <Item
          key="2"
          className={s.stepContent}
          pose={current === 2 ? 'open' : 'closed'}
          initialPose="closed"
        >
          {this.renderStep2()}
        </Item>
        <Item
          key="3"
          className={s.stepContent}
          pose={current === 3 ? 'open' : 'closed'}
          initialPose="closed"
        >
          {this.renderStep3()}
        </Item>
      </div>
    );
  }

  renderStep1() {
    const { userProgress } = this.props;
    const { fromLanguage, toLanguage } = userProgress;
    return (
      <div>
        <div className={s.step1}>
          <div>
            <div className={s.label}>Translate from</div>
            <Select
              placeholder="Translate from"
              data={options}
              className={s.select}
              handleChange={fl => this.props.updateFromLanguage(fl)}
              selected={fromLanguage}
              id="from"
            />
          </div>
          <div>
            <div className={s.label}>Translate to</div>
            <Select
              placeholder="Translate to"
              data={options}
              className={s.select}
              handleChange={tl => this.props.updateToLanguage(tl)}
              selected={toLanguage}
              id="to"
            />
          </div>
          <div className={s.break} />
          <button
            type="button"
            className={((!fromLanguage || !toLanguage || fromLanguage === toLanguage) && s.disable) || ''}
            onClick={() => this.handleStep1()}
          >
            Next step
          </button>
        </div>
      </div>
    );
  }

  renderTextStats() {
    const { userProgress } = this.props;
    const { userText } = userProgress;
    return (
      <aside>
        <h4>Words in uploaded file(s) {this.state.fileWords}</h4>
        <h4>Words in inserted text {wordCounter(userText)}</h4>
      </aside>
    );
  }

  renderStep2() {
    const { fileWords } = this.state;
    const { userText } = this.props.userProgress;
    const totalWords = fileWords + wordCounter(userText);
    const price = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(totalWords * PRICE);

    return (
      <div className={s.step2}>
        {totalWords > 0 && <h1>Price for translation would be {price}</h1>}
        <Dropzone
          onDrop={acceptedFiles => {
            this.props.updateUserFile(acceptedFiles);
            this.setState({ fileWords: 0 });

            if (acceptedFiles) {
              acceptedFiles.forEach(file => {
                const reader = new FileReader();
                reader.onload = event => {
                  const { fileWords } = this.state;
                  this.setState({
                    fileWords: fileWords + wordCounter(event.target.result),
                  });
                };
                reader.readAsText(file);
              });
            }
          }}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
          }) => {
            const baseStyle = {
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '20px',
              borderWidth: 1,
              borderRadius: 2,
              borderColor: '#c4424f',
              borderStyle: 'dashed',
              backgroundColor: '#fff',
              color: '#000',
              outline: 'none',
              transition: 'border .24s ease-in-out',
            };

            const activeStyle = {
              background: '#2196f3',
            };

            const acceptStyle = {
              background: '#00e676',
            };

            const rejectStyle = {
              background: '#ff1744',
            };

            const style = useMemo(
              () => ({
                ...baseStyle,
                ...(isDragActive ? activeStyle : {}),
                ...(isDragAccept ? acceptStyle : {}),
                ...(isDragReject ? rejectStyle : {}),
              }),
              [isDragActive, isDragReject],
            );

            return (
              <section className={s.dropContainer}>
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p>
                    Drag &apos;n&apos; drop some files here, or click to select
                    files
                  </p>
                </div>
                {this.getUploadedFiles()}
              </section>
            );
          }}
        </Dropzone>
        <p>Type your text here</p>
        <textarea
          onKeyUp={e => this.props.updateUserText(e.target.value)}
          defaultValue={
            this.props.userProgress && this.props.userProgress.userText
              ? this.props.userProgress.userText
              : ''
          }
        />
        {totalWords > 0 && <h2>Price for translation would be {price}</h2>}
        {this.renderTextStats()}
      </div>
    );
  }

  renderStep3() {
    return (
      <div>
        <p>step 3</p>
      </div>
    );
  }

  render() {
    return (
      <div className={s.root}>
        <Infobar />
        <div className={s.container}>{this.renderStep()}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ userProgress }) => ({
  userProgress,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateHelp,
      updateStep,
      updateFromLanguage,
      updateToLanguage,
      updateUserFile,
      updateUserText,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(s)(Home));
