/* eslint global-require: 0 */

const LANGUAGES = [
  {
    name: 'Polish',
    slug: 'polish',
    flag: require('./republic-of-poland.svg'),
  },
  {
    name: 'German',
    slug: 'german',
    flag: require('./germany.svg'),
  },
  {
    name: 'French',
    slug: 'french',
    flag: require('./france.svg'),
  },
  {
    name: 'Portuguese',
    slug: 'portuguese',
    flag: require('./portugal.svg'),
  },
  {
    name: 'Spanish',
    slug: 'spanish',
    flag: require('./spain.svg'),
  },
  {
    name: 'Dutch',
    slug: 'dutch',
    flag: require('./netherlands.svg'),
  },
  {
    name: 'Swedish',
    slug: 'swedish',
    flag: require('./sweden.svg'),
  },
  {
    name: 'Ukrainian',
    slug: 'ukrainian',
    flag: require('./ukraine.svg'),
  },
  {
    name: 'Greek',
    slug: 'greek',
    flag: require('./greece.svg'),
  },
  {
    name: 'Romanian',
    slug: 'romanian',
    flag: require('./romania.svg'),
  },
  {
    name: 'Bulgarian',
    slug: 'bulgarian',
    flag: require('./bulgaria.svg'),
  },
  {
    name: 'Russian',
    slug: 'russian',
    flag: require('./russia.svg'),
  },
  {
    name: 'Czech',
    slug: 'czech',
    flag: require('./czech-republic.svg'),
  },
  {
    name: 'Italian',
    slug: 'italian',
    flag: require('./italy.svg'),
  },
  {
    name: 'Croatian',
    slug: 'croatian',
    flag: require('./croatia.svg'),
  },
  {
    name: 'Serbian',
    slug: 'serbian',
    flag: require('./serbia.svg'),
  },
  {
    name: 'Danish',
    slug: 'danish',
    flag: require('./denmark.svg'),
  },
  {
    name: 'Turkish',
    slug: 'turkish',
    flag: require('./turkey.svg'),
  },
  {
    name: 'English',
    slug: 'english',
    flag: require('./england.svg'),
  },
  {
    name: 'Japanese',
    slug: 'japanese',
    flag: require('./japan.svg'),
  },
  {
    name: 'Persian / Farsi',
    slug: 'persian',
    flag: require('./iran.svg'),
  },
  {
    name: 'Hungarian',
    slug: 'hungarian',
    flag: require('./hungary.svg'),
  },
  {
    name: 'Hebrew',
    slug: 'hebrew',
    flag: require('./israel.svg'),
  },
  {
    name: 'Swahili',
    slug: 'swahili',
    flag: require('./kenya.svg'),
  },
  {
    name: 'Finnish',
    slug: 'finnish',
    flag: require('./finland.svg'),
  },
  {
    name: 'Norwegian',
    slug: 'norwegian',
    flag: require('./norway.svg'),
  },
  {
    name: 'Albanian',
    slug: 'albanian',
    flag: require('./albania.svg'),
  },
  {
    name: 'Chinese (Simplified)',
    slug: 'chinese-simplified',
    flag: require('./china.svg'),
  },
  {
    name: 'Chinese (Traditional)',
    slug: 'chinese-traditional',
    flag: require('./china.svg'),
  },
  {
    name: 'Thai',
    slug: 'thai',
    flag: require('./thailand.svg'),
  },
  {
    name: 'Filipino',
    slug: 'filipino',
    flag: require('./philippines.svg'),
  },
  {
    name: 'Tagalog',
    slug: 'tagalog',
    flag: require('./philippines.svg'),
  },
  {
    name: 'Urdu',
    slug: 'urdu',
    flag: require('./pakistan.svg'),
  },
  {
    name: 'Vietnamese',
    slug: 'vietnamese',
    flag: require('./vietnam.svg'),
  },
  {
    name: 'Arabic',
    slug: 'arabic',
    flag: require('./united-arab-emirates.svg'),
  },
  {
    name: 'Indonesian',
    slug: 'indonesian',
    flag: require('./indonesia.svg'),
  },
  {
    name: 'Bengali',
    slug: 'bengali',
    flag: require('./india.svg'),
  },
  {
    name: 'Hindi',
    slug: 'hindi',
    flag: require('./india.svg'),
  },
  {
    name: 'Punjabi',
    slug: 'punjabi',
    flag: require('./india.svg'),
  },
  {
    name: 'Malay',
    slug: 'malay',
    flag: require('./brunei.svg'),
  },
  {
    name: 'Bosnian',
    slug: 'bosnian',
    flag: require('./bosnia-and-herzegovina.svg'),
  },
  {
    name: 'Korean',
    slug: 'korean',
    flag: require('./south-korea.svg'),
  },
];

export default function languages() {
  return LANGUAGES.sort(
    (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
  );
}
