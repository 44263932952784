import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Infobar.scss';
import stepJoin from './images/stepSpacer.png';
import stepsData from './stepData';
import { updateStep } from '../../actions/userProgress';

class Infobar extends React.Component {
  static propTypes = {
    userProgress: PropTypes.shape({
      completed: PropTypes.arrayOf(PropTypes.number),
      current: PropTypes.number,
      help: PropTypes.string,
    }).isRequired,
    updateStep: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.stepKey = null;
  }

  getHelp() {
    const { userProgress } = this.props;
    const {
      fromLanguage,
      toLanguage,
      userFile,
      userText,
      current,
    } = userProgress;
    switch (true) {
      case current === 1 && !fromLanguage && !toLanguage:
        return 'Please select input and output language';
      case current === 1 && !toLanguage:
        return 'Please select output language';
      case current === 1 && !fromLanguage:
        return 'Please select input language';
      case current === 1 && fromLanguage === toLanguage:
        return 'Can`t translate to identical language';
      case current === 1 && !userProgress.completed.includes(1):
        return 'To complete this step hit Next button.';
      case current === 1 && userProgress.completed.includes(1):
        return 'Step is completed.';
      case current === 2 && !(userFile || userText):
        return 'Select file for translate or type your text.';
      default:
        return '';
    }
  }

  handleStepClick(id) {
    const { userProgress } = this.props;
    if (
      userProgress.completed.includes(id) ||
      userProgress.completed.includes(id - 1)
    )
      this.props.updateStep(id);
  }

  renderStep(data) {
    const { userProgress } = this.props;
    if (data.id === null)
      return (
        <img src={stepJoin} alt="Next step" key={`separator-${this.stepKey}`} />
      );

    this.stepKey = data.id;
    const cssClasses = [s.step];
    if (data.id === userProgress.current) cssClasses.push(s.active);
    if (userProgress.completed.includes(data.id)) cssClasses.push(s.completed);

    return (
      <button
        className={cx(cssClasses)}
        key={data.id}
        onClick={() => this.handleStepClick(data.id)}
        type="button"
      >
        <img src={data.icon} alt="step1" />
        <div>
          <h3>{data.title}</h3>
          <p>{data.description}</p>
        </div>
      </button>
    );
  }

  renderSteps() {
    const steps = stepsData.map(e => this.renderStep(e));
    return <div className={s.content}>{steps}</div>;
  }

  render() {
    const { help } = this.props.userProgress;
    return (
      <div className={s.root}>
        <div className={s.container}>{this.renderSteps()}</div>
        <div className={s.help}>{this.getHelp()}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ userProgress }) => ({
  userProgress,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateStep }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(s)(Infobar));
