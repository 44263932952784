import step1 from './images/stepIcons/step1.png';
import step2 from './images/stepIcons/step2.png';
import step3 from './images/stepIcons/step3.png';

export default [
  {
    id: 1,
    title: 'Step 1',
    description: 'Please select language.',
    icon: step1,
  },
  {
    id: null,
  },
  {
    id: 2,
    title: 'Step 2',
    description: 'Upload file for traduse.',
    icon: step2,
  },
  {
    id: null,
  },
  {
    id: 3,
    title: 'Step 3',
    description: 'Pay to get started translate your text.',
    icon: step3,
  },
];
